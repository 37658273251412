/* Layout.css */
.content {
    margin-bottom: 100px;
    /* Adjust according to your footer height */
}

/* Footer.css */
footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: white;
    padding: 0px 0;
    text-align: center;
}