.kitchen-container {
    padding: 20px;
    color: white;
    text-align: center;
}

.kitchen-container h2 {
    margin-bottom: 20px;
}

.view-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #61dafb;
    color: #282c34;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.kitchen button:hover {
    background-color: #21a1f1;
}

.meal-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.meal-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.meal-card {
    background-color: #454444;
    border: 1px solid #444;
    border-radius: 8px;
    overflow: hidden;
    width: 200px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s;
    margin: 0px auto;

}

.meal-card h3 {
    margin-bottom: 10px;
    color: #61dafb;
}

.meal-card:hover {
    transform: scale(1.05);
}

.meal-info {
    text-align: center;
}

.meal-info h3 {
    margin: 10px 0;
    color: #61dafb;
}

.meal-info p {
    margin: 5px 0;
    color: #aaa;
}

.meal-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
    color: white;
}

.meal-table th,
.meal-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #444;
}

.meal-table th {
    background-color: #333;
    color: #61dafb;
}

.meal-table tr:hover {
    background-color: #444;
}